import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './services/theme/theme.service';
import { VISUAL_PROJECT } from './constants/visual-identity';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
// import { getGPUTier } from 'detect-gpu';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private theme: ThemeService,
    public translate: TranslateService,
    private title: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    translate.setDefaultLang('pt-br');

    const language = localStorage.getItem('language');

    let browserLang = translate.getBrowserLang();
    browserLang = browserLang === 'pt' ? 'pt-br' : browserLang;
    browserLang = browserLang?.match(/pt-br|en/) ? browserLang : 'pt-br';
    translate.use(language || browserLang);
  }

  visual_project = VISUAL_PROJECT;

  ngOnInit() {
    this.theme.loadCurrentTheme();
    this.title.setTitle(this.visual_project.title);
    this.updateFavicon(this.visual_project.icon);
    this.getSystemSpecs();
  }

  private updateFavicon(iconUrl: string) {
    const link: HTMLLinkElement =
      this.document.querySelector('link[rel*="icon"]') ||
      this.document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = iconUrl;

    this.document.head.appendChild(link);
  }

  private async getSystemSpecs() {
    // const gpuTier = await getGPUTier();
    // const battery = await navigator.getBattery?.();

    // console.log('System Specs: ', {
    //   userAgent: navigator.userAgent,
    //   width: window.innerWidth,
    //   height: window.innerHeight,
    //   deviceMemory: navigator.deviceMemory || 'Desconhecido',
    //   cores: navigator.hardwareConcurrency,
    //   gpuTier,
    //   battery: battery || 'Desconhecido',
    // });
  }
}
